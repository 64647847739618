import { Translation } from '../../types';

export const ua: Translation = {
  authorName: "Антон Коваленко",
  projects: "Проекти",
  blog: "Блог",
  profile: {
    title: "Антон Коваленко | Будую продукти",
    description: "Перетворюю ідеї в SaaS продукти в MarTech | Захоплений стартапами, криптовалютою та новітніми технологіями.",
    followLinkedIn: "Слідкуйте за моїм LinkedIn профілем:"
  },
  projectCards: {
    zapytai: {
      title: "ZapytAI",
      description: "Ваш помічник для пошуку відповідей. Завантажуйте CSV та Excel файли та отримуйте швидкі та точні відповіді на ваші запитання."
    },
    yaktobi: {
      title: "Yaktobi",
      description: "Наш сервіс допомагає вивчити шлях клієнта та зібрати глибоку аналітику, як онлайн бізнесу, так і оффлайн."
    },
    inprogress: {
      title: "CTRL+LIKE",
      description: "Наш сервіс допоможе поліпшити взаємодію з клієнтами в соціальних мережах. " +
        "Ми знаємо більше 25+ способів привернути ваших клієнтів."
    }
  },
  blogPosts: {
    first_post: {
      title: "Що таке NPS: як його розрахувати та використовувати",
      description: "Індекс споживчої лояльності (NPS) — це показник рівня прихильності клієнтів до бренду.",
      content: `
        <h2>Розуміння NPS</h2>
        <p>Net Promoter Score (NPS) - це широко використовуваний показник лояльності клієнтів, який вимірює їхню готовність рекомендувати продукти або послуги компанії іншим. Він служить індикатором задоволеності та лояльності клієнтів.</p>

        <h2>Як розрахувати NPS</h2>
        <p>NPS розраховується на основі відповідей на одне запитання: "Наскільки ймовірно, що ви порекомендуєте наш продукт/послугу другу чи колезі?" Респонденти дають оцінку від 0 (зовсім малоймовірно) до 10 (надзвичайно ймовірно).</p>
        
        <p>На основі їхніх відповідей клієнти поділяються на три групи:</p>
        <ul>
          <li>Промоутери (оцінка 9-10): Лояльні ентузіасти, які продовжуватимуть купувати та рекомендувати іншим</li>
          <li>Пасивні (оцінка 7-8): Задоволені, але неактивні клієнти</li>
          <li>Детрактори (оцінка 0-6): Незадоволені клієнти, які можуть зашкодити вашому бренду</li>
        </ul>

        <p>NPS розраховується шляхом віднімання відсотка Детракторів від відсотка Промоутерів:</p>
        <p>NPS = % Промоутерів - % Детракторів</p>

        <h2>Чому NPS важливий</h2>
        <p>NPS цінний тим, що надає чіткий та дієвий показник, який корелює з ростом бізнесу. Компанії з високими показниками NPS зазвичай перевершу��ть своїх конкурентів за темпами зростання доходів та утримання клієнтів.</p>

        <h2>Найкращі практики для опитувань NPS</h2>
        <p>1. Зберігайте простоту та послідовність</p>
        <p>2. Опитуйте клієнтів у відповідних точках взаємодії</p>
        <p>3. Доповнюйте відкритими запитаннями</p>
        <p>4. Дійте на основі отриманого зворотного зв'язку</p>
        <p>5. Відстежуйте зміни з часом</p>

        <h2>Використання даних NPS</h2>
        <p>Справжня цінність NPS полягає в тому, що ви робите з даними. Використовуйте їх для:</p>
        <ul>
          <li>Визначення областей для покращення</li>
          <li>Прогнозування відтоку клієнтів</li>
          <li>Керування розробкою продукту</li>
          <li>Порівняння з конкурентами</li>
        </ul>
      `
    },
    second_post: {
      title: "Створення SaaS продуктів",
      description: "Ключові висновки та уроки з розробки успішних SaaS продуктів.",
      content: `
        <h2>Розуміння основ SaaS</h2>
        <p>Software as a Service (SaaS) революціонізував спосіб надання цінності клієнтам. Замість одноразових покупок, SaaS працює за моделлю підписки, забезпечуючи постійний сервіс та регулярні оновлення. Ця модель вимагає іншого підходу як до розробки, так і до відстеження метрик.</p>

        <h2>Важливі метрики для SaaS-стартапів</h2>
        <p>При запуску SaaS-продукту варто зосередитись на таких ключових метриках:</p>

        <h3>1. Щомісячний регулярний дохід (MRR)</h3>
        <p>MRR є життєвою силою будь-якого SaaS-бізнесу. Він представляє ваш передбачуваний щомісячний потік доходів і допомагає прогнозувати зростання. Відстежуйте:</p>
        <ul>
          <li>Новий MRR від нових клієнтів</li>
          <li>Розширення MRR від існуючих клієнтів, які переходять на вищі тарифи</li>
          <li>Втрачений MRR від скасувань</li>
          <li>Темп зростання чистого MRR</li>
        </ul>

        <h3>2. Вартість залучення клієнта (CAC)</h3>
        <p>Розуміння того, скільки коштує залучення кожного клієнта, є критично важливим. Розраховуйте:</p>
        <ul>
          <li>Загальні витрати на маркетинг та продажі</li>
          <li>Вартість за лід</li>
          <li>Коефіцієнт конверсії від ліда до клієнта</li>
          <li>Час окупності CAC (має бути менше 12 місяців)</li>
        </ul>

        <h3>3. Довічна цінність клієнта (CLV)</h3>
        <p>CLV допомагає визначити, скільки можна витрачати на залучення, залишаючись прибутковим:</p>
        <ul>
          <li>Середня тривалість підписки</li>
          <li>Середній дохід на користувача (ARPU)</li>
          <li>Можливості допродажу та крос-продажу</li>
          <li>Співвідношення CLV/CAC (прагніть до 3:1 або краще)</li>
        </ul>

        <h3>4. Відтік клієнтів</h3>
        <p>Контролюйте як відтік клієнтів, так і доходів:</p>
        <ul>
          <li>Щомісячний показник відтоку клієнтів</li>
          <li>Показник відтоку доходів</li>
          <li>Активність користувачів перед відтоком</li>
          <li>Причини скасування</li>
        </ul>

        <h2>Стратегія розробки продукту</h2>
        <p>Успішні SaaS-продукти вимагають збалансованого підходу до розробки:</p>
        <ol>
          <li>Почніть з мінімально життєздатного продукту (MVP)</li>
          <li>Зосередьтеся на основних функціях, які вирішують конкретні проблеми</li>
          <li>Впровадьте постійні цикли зворотного зв'язку</li>
          <li>Плануйте масштабованість з першого дня</li>
          <li>Приділяйте увагу користувацькому досвіду та онбордингу</li>
        </ol>

        <h2>Фокус на успіху клієнтів</h2>
        <p>У SaaS успіх клієнтів є критичним для сталого зростання:</p>
        <ul>
          <li>Розробіть комплексний процес онбордингу</li>
          <li>Забезпечте відмінну підтримку клієнтів</li>
          <li>Створюйте навчальний контент та ресурси</li>
          <li>Проводьте регулярні перевірки з клієнтами</li>
          <li>Відстежуйте патерни використання та залученості</li>
        </ul>

        <h2>Поширені виклики та рішення</h2>
        <p>Будьте готові вирішувати ці поширені проблеми:</p>
        <ul>
          <li>Розростання функціоналу - Зберігайте фокус на основній цінності</li>
          <li>Технічний борг - Плануйте регулярні спринти з обслуговування</li>
          <li>Стратегія ціноутворення - Починайте просто, коригуйте на основі цінності</li>
          <li>Утримання клієнтів - Зосередьтеся на постійному наданні цінності</li>
        </ul>

        <h2>Висновок</h2>
        <p>Створення успішного SaaS-продукту вимагає уважного ставлення як до метрик, так і до потреб клієнтів. Зосередьтеся на постійному наданні цінності, вимірюванні правильних метрик та підтримці сильного зворотного зв'язку з користувачами. Пам'ятайте, що успіх у SaaS - це марафон, а не спринт.</p>
      `
    },
    third_post: {
      title: "eNPS: Вимірювання задоволеності команди",
      description: "Як вимірювати та покращувати задоволеність співробітників за допомогою методології eNPS у вашій компанії.",
      content: `
        <h2>Що таке eNPS?</h2>
        <p>Employee Net Promoter Score (eNPS) – це адаптація традиційної методології NPS, розроблена для вимірювання задоволеності та лояльності співробітників. Вона допомагає організаціям зрозуміти, як їхні працівники ставляться до свого робочого місця та виявити області для покращення корпоративної культури.</p>

        <h2>Як розрахувати eNPS</h2>
        <p>Подібно до клієнтського NPS, eNPS базується на одному запитанні: "За шкалою від 0 до 10, наскільки ймовірно, що ви порекомендуєте нашу компанію як місце роботи?" Категорії оцінювання:</p>
        <ul>
          <li>Промоутери (9-10): Високозалучені співробітники, які активно просувають вашу компанію</li>
          <li>Пасивні (7-8): Задоволені, але не ентузіастичні співробітники</li>
          <li>Детрактори (0-6): Незадоволені співробітники, які можуть поширювати негативні відгуки</li>
        </ul>

        <h2>Вплив на корпоративну культуру</h2>
        <p>Регулярне вимірювання eNPS може значно вплинути на вашу організацію кількома способами:</p>
        <ul>
          <li>Раннє виявлення проблем: Визначення проблем до того, як вони призведуть до звільнень</li>
          <li>Покращення утримання: Проактивне вирішення проблем для збереження цінних членів команди</li>
          <li>Покращений рекрутинг: Високий eNPS може привабити найкращих фахівців</li>
          <li>Краща продуктивність: Залучені співробітники зазвичай показують кращі результати</li>
        </ul>

        <h2>Найкращі практики впровадження</h2>
        <p>Щоб отримати максимальну користь від eNPS:</p>
        <ol>
          <li>Зберігайте анонімність: Забезпечте анонімність відповідей для отримання чесного зворотного зв'язку</li>
          <li>Регулярні опитування: Проводьте опитування щоквартально для відстеження тенденцій</li>
          <li>Додаткові запитання: Включайте відкриті запитання для детального зворотного зв'язку</li>
          <li>Швидка реакція: Оперативно реагуйте на відгуки, щоб показати, що ви цінуєте думку співробітників</li>
          <li>Прозора комунікація: Діліться результатами та планами дій з командою</li>
        </ol>

        <h2>Поширені виклики та рішення</h2>
        <p>При впровадженні eNPS організації часто стикаються з кількома викликами:</p>
        <ul>
          <li>Низький рівень відповідей: Зробіть опитування легкодоступними та поясніть їх важливість</li>
          <li>Втома від опитувань: Тримайте опитування короткими та зосередьтеся на ключових метриках</li>
          <li>Відсутність дій: Створюйте чіткі плани дій на основі зворотного зв'язку</li>
          <li>Страх негативних відгуків: Розвивайте культуру відкритого спілкування</li>
        </ul>

        <h2>Ефективне використання даних eNPS</h2>
        <p>Справжня цінність eNPS полягає в тому, як ви використовуєте дані:</p>
        <ul>
          <li>Відстежуйте тенденції з часом для вимірювання впливу змін</li>
          <li>Порівнюйте показники між відділами для визначення найкращих практик</li>
          <li>Використовуйте інсайти для покращення програм онбордингу та навчання</li>
          <li>Розробляйте цільові стратегії утримання</li>
        </ul>

        <h2>Висновок</h2>
        <p>eNPS є потужним інструментом для розуміння та покращення задоволеності співробітників. При правильному впровадженні він може допомогти створити більш залучену, продуктивну та лояльну робочу силу. Пам'ятайте, що сам показник – це лише початок, справжня цінність полягає в діях, які ви вживаєте на основі отриманого зворотного зв'язку.</p>
      `
    }
  },
  back: 'Повернутись'
}; 