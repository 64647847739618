import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { ProjectId } from '../../types';
import './ProjectCard.css';
// Импортируем изображения напрямую
import project1 from '../../assets/images/project1.png';
import project2 from '../../assets/images/project2.png';
import project3 from '../../assets/images/project3.png';

interface ProjectCardProps {
  project: ProjectId;
  href?: string;
}

const getImagePath = (project: ProjectId): any => {
  switch (project) {
    case 'zapytai':
      return project1;
    case 'yaktobi':
      return project2;
    case 'inprogress':
      return project3;
    default:
      return project1;
  }
};

export const ProjectCard: React.FC<ProjectCardProps> = ({ project, href }) => {
  const navigate = useNavigate();
  const { translations, language } = useLanguage();
  const projectData = translations?.[language]?.projectCards?.[project];

  const handleClick = () => {
    if (href && !href.startsWith('http')) {
      navigate(`${href}?lang=${language}`);
    }
  };

  if (!projectData) return null;

  return (
    <div 
      className={`project-card ${!href ? 'no-link' : ''}`} 
      onClick={handleClick}
    >
      <img 
        src={getImagePath(project)}
        alt={projectData.title}
        className="project-image"
      />
      <h3>{projectData.title}</h3>
      <p>{projectData.description}</p>
      {project === 'zapytai' && (
        <a 
          href="https://zapytai.antonkovalenko.com/ua/" 
          className="project-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://zapytai.antonkovalenko.com
        </a>
      )}
    </div>
  );
}; 