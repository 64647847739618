import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { updateLanguageInUrl } from './utils/url';

// Проверяем URL и добавляем параметр языка
if (window.location.hostname.startsWith('www.')) {
  window.location.href = `https://antonkovalenko.com${window.location.pathname}${window.location.search}`;
} else if (!window.location.search.includes('lang=')) {
  updateLanguageInUrl('en');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Добавьте регистрацию Service Worker
if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('SW registered successfully:', registration.scope);
      })
      .catch(error => {
        console.error('SW registration failed:', error);
      });
  });

  // Добавляем обработку обновлений
  let refreshing = false;
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (!refreshing) {
      refreshing = true;
      window.location.reload();
    }
  });
} 