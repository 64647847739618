import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MainPage } from './components/MainPage';
import { ProjectPage } from './components/ProjectPage';
import { BlogPage } from './components/BlogPage';
import { BlogPostPage } from './components/BlogPostPage/BlogPostPage';
import { LanguageProvider } from './context/LanguageContext';
import { ThemeProvider } from './context/ThemeContext';
import { Helmet } from 'react-helmet';
import './App.css';

const App: React.FC = () => {
  React.useEffect(() => {
    // Редирект с www на non-www
    if (window.location.hostname.startsWith('www.')) {
      window.location.href = `https://antonkovalenko.com${window.location.pathname}${window.location.search}`;
    }
    
    // Редирект с /?lang=en на / для англоязычных пользователей
    const urlParams = new URLSearchParams(window.location.search);
    if (window.location.pathname === '/' && urlParams.get('lang') === 'en' && document.referrer.includes('google')) {
      window.history.replaceState({}, document.title, '/');
    }
  }, []);

  return (
    <ThemeProvider>
      <LanguageProvider>
        <Helmet>
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Anton Kovalenko" />
          <meta property="og:image" content="https://antonkovalenko.com/images/social-preview.png" />
        </Helmet>
        <Router>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/project/:projectId" element={<ProjectPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:postId" element={<BlogPostPage />} />
          </Routes>
        </Router>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App; 